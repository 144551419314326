<template>
  <div class="">
    <div class="wallpaper-login">
      <div class="container">
        <div class="row flex-column-reverse flex-lg-row mx-auto">
          <div class="col-lg-5 px-0">
            <div
              v-if="!registerActive"
              class="card login"
              v-bind:class="{ error: emptyFields }"
            >
              <p class="pb-4 d-none d-lg-block text-blue title mb-0 font-weight-semibold">
                Login
              </p>
              <form class="form-group d-flex flex-wrap">
                <div class="col-lg-12">
                  <label for="email" class="mb-0">Email</label>
                  <input
                    v-model="emailLogin"
                    type="email"
                    class="form-control"
                    placeholder=""
                    required
                  />
                </div>
                <div class="col-lg-12">
                  <label for="password" class="mb-0">Password</label>
                  <input
                    v-model="passwordLogin"
                    type="password"
                    class="form-control"
                    placeholder=""
                    required
                  />
                </div>
                <div class="col-lg-12 mb-3 text-right">
                  <a href="#" class="pb-2 px-4">Forgot your password?</a>
                </div>
                <div class="col-lg-12">
                  <input
                    type="submit"
                    value="Sign In"
                    class="col col-lg-5 btn btn-primary mr-lg-5"
                    @click="doLogin"
                  />
                  <input
                    type="submit"
                    value="Create Account"
                    class="col col-lg-5 btn btn-outline-primary text-dark"
                    @click="doRegister"
                  />
                </div>

                <p class="d-none d-lg-block hor-line text-gray"><span>Or</span></p>
                <div class="d-none d-lg-flex flex-row justify-content-center pb-5">
                  <a href="#" class="fb btn btn-facebook d-flex px-1 py-2">
                    <img
                      alt="Facebook icon"
                      class="mx-0"
                      srcset="
                        https://img.icons8.com/color/2x/facebook-new.png 2x
                      "
                      style="height: 25px; width: 25px;"
                    />
                    Login with Facebook
                  </a>
                  <a
                    href="#"
                    class="google btn btn-outline-secondary d-flex px-1 py-2 ml-3"
                  >
                    <img
                      alt="Google icon"
                      class="px-0"
                      srcset="
                        https://img.icons8.com/color/2x/google-logo.png 2x
                      "
                      style="height:25px;width:25px;"
                    />
                    Login with Google+
                  </a>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-5 px-0 image-container position-relative"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-facebook {
  background: #39528e;
  color: #ffffff;
}
.form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
  transition: none;
}
.form-control:focus {
  border: none !important;
}
label {
  color: #ced4da;
}
.btn-primary {
  background: #574fd8;
}
.title {
  font-size: 30px;
  font-family: sans-serif;
}
.text-gray {
  color: #ced4da;
}
.text-blue {
  color: #574fd8;
}
.image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../assets/images/login-image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.hor-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ced4da;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
.hor-line span {
  background: #fff;
  padding: 0 10px;
}
.text-dark {
  color: #000;
}
.login {
  padding: 2.5rem 2.5rem 0 2.5rem;
  border: none;
}

.form-group input {
  margin-bottom: 20px;
}

.login-page {
  align-items: center;
  display: flex;
  height: 100vh;
}
.wallpaper-login {
  background: #f5f5f5 no-repeat center center;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex; 
  align-items: center; 
}
@media (max-width: 767px) {
  .image-container {
    background-size: cover;
    height: 300px;
    width: 100%;
  }
  .container {
    padding: 0;
  }
}
</style>
