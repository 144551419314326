import {
    createStore
} from "vuex"
import Songs from '@/assets/songs.json';

const store = createStore({
    state: {
        audio: new Audio(),
        songs: Songs.songs,
        songsCount: 0,
        selectedSong: null,
        isLoading: false,
        isPlaying: false,
        isPaused: false,
        isStopped: false,
        isMuted: false,
        isContinuousPlaying: 0,
        volume: 1,
        buffered: 0,
    },
    getters: {
        getTrackState: (state) => state,
        getAudio: (state) => state.audio
    },
    mutations: {
        updateCurrentTrack(state, payload) {
            let song = state.songs.find((song) => song.id == payload.trackId)
            state.selectedSong = song;
            state.audio.src = song.audio;
        },
        //  Play Track
        updateTrack(state, payload) {
            if (!state.isPlaying) {
                state.audio.load();

                state.selectedSong = payload.track;
                state.audio.src = payload.track.audio;

                state.isPlaying = true;
                state.isLoading = false;
                state.isPaused = false;
                state.isStopped = false;

                let playTrack = state.audio.play();
                if (playTrack) {
                    playTrack.catch((e) => {
                        console.log(e);
                        if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                            state.audio.load();
                            state.isPlaying = false;
                            state.isStopped = true;
                        }
                    });
                }
                state.audio.volume = state.volume;
            }
        },
        //  Stop Track
        updateToStopTrack(state) {
            state.audio.load();
            state.isStopped = true;
            state.isPlaying = false;
            state.isLoading = false;
            state.isPaused = false;
        },
        //  Pause Track
        updateToPauseTrack(state) {
            state.audio.pause();
            state.isPaused = true;
            state.isPlaying = false;
            state.isLoading = false;
            state.isStopped = false;
        },
        //  Play paused Track
        updatePauseTrack(state, payload) {
            state.isLoading = false;
            state.isStopped = false;
            state.isPaused = false;
            state.isPlaying = true;
            state.audio.currentTime = payload.time;
            state.audio.play();
        },
        //  Seeking track time
        updateSeekTrack(state, payload) {
            state.audio.currentTime = payload.time;
        },
        //  Change Volume
        updateVolume(state, payload) {
            state.audio.volume = payload.volume;
            state.volume = payload.volume
        },
        updateContinuousPlayStatus(state, payload) {
            state.isContinuousPlaying = payload.status
        }
    },
    actions: {
        changeTrack({
            commit
        }, payload) {
            commit('updateTrack', {
                track: payload
            })
        },
        changeToStopTrack({
            commit
        }) {
            commit('updateToStopTrack')
        },
        changeToPauseTrack({
            commit
        }) {
            commit('updateToPauseTrack')
        },
        changeSeekTrack({
            commit
        }, time) {
            commit('updateSeekTrack', {
                time: time
            })
        },
        changeVolume({
            commit
        }, volume) {
            commit('updateVolume', {
                volume: volume
            })
        },
        changePauseTrack({
            commit
        }, time) {
            commit('updatePauseTrack', {
                time
            })
        },
        changeCurrentTrack({
            commit
        }, trackId) {
            commit('updateCurrentTrack', {
                trackId
            })
        },
        changeContinuousPlay({
            commit
        }, status) {
            return commit('updateContinuousPlayStatus', {
                status: status
            });
        },
    }
})

export default store;