<template>
  <div class="bd-sidebar border-bottom-0 col-12 px-lg-5 py-lg-5">
    <ul class="nav d-flex flex-row flex-md-column">
      <li class="user p-3">
        <img class="user-avatar" :src="defaultBannerImage" />
      <p class="user-name text-20 text-white mb-0 pt-2 d-none d-md-block font-weight-bold">Peter Roy</p>
      </li>
      <li class="my-3">
        <a href="/" class="bd-toc-link" target="_self"><img width="25" src="../../assets/images/discover.png"/><span class="mx-3 d-none d-md-block">Discover</span></a>
      </li>
      <li class="my-3">
        <a href="/" class="bd-toc-link" target="_self"><img width="25" src="../../assets/images/search.png"/><span class="mx-3 d-none d-md-block">Search</span></a>
      </li>
      <li class="my-3">
        <a href="/" class="bd-toc-link" target="_self"><img width="25" src="../../assets/images/favourites.png"/><span class="mx-3 d-none d-md-block">Favourites</span></a>
      </li>
      <li class="my-3">
        <a href="/" class="bd-toc-link" target="_self"><img width="25" src="../../assets/images/library.png"/><span class="mx-3 d-none d-md-block">Library</span></a>
      </li>
      <li class="my-3">
        <a href="/" class="bd-toc-link" target="_self"><img width="25" src="../../assets/images/learning.png"/><span class="mx-3 d-none d-md-block">Learning</span></a>
      </li>
      <li class="my-3">
        <a href="/" class="bd-toc-link" target="_self"><img width="25" src="../../assets/images/reading.png"/><span class="mx-3 d-none d-md-block">Reading</span></a>
      </li>
      <li class="my-3">
        <a href="/" class="bd-toc-link" target="_self"><img width="25" src="../../assets/images/logout.png"/><span class="mx-3 d-none d-md-block">Logout</span></a>
      </li>
    </ul>
  </div>
</template>

<script>
import BannerImg from "@/assets/images/avatar.png";
export default {
  components: {
    
  },
  data() {
    return {
      search: "",
      defaultBannerImage: BannerImg,
    };
  },
};
</script>

<style scoped>
.user-avatar {
  border-radius: 50%;
  width: 80px;
}
.bd-sidebar {
    background: #574FD8;
    overflow-y: scroll;
}

.bd-toc-link {
  display: inline-flex;
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
  text-align: left;
}
.form-control {
  display: block;
  width: 90%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.bd-sidebar {
  position: sticky;
  top: 0rem;
  left: 0;
  z-index: 1000;
  height: calc(100vh - 0rem);
}
.bd-search {
  position: relative;
  padding: 1rem 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

@media (max-width: 767px) {
  .bd-sidebar {
    position: fixed;
    top: unset;
    bottom: 0;
    height: fit-content;
  }
  .nav {
    overflow-x: scroll;
    flex-wrap: unset;
  }
  .user-avatar {
    width: 30px;
  }
  
  .menu-toggle {
    width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
  }
  .menu-toggle div {
    width: 24px;
    height: 1px;
    background: #5f5f5f;
    margin: 3px 0;
  }
}
</style>
