<template>
  <div class="row mr-0">
    <div class="col-md-3 px-0">
      <playerSidebar />
    </div>
    <div class="col-md-9 px-0">
      <div class="h-75">
        <div
          class="hero-banner"
          data-ride="carousel"
          data-pause="hover"
          data-interval="false"
        >
          <div class="container-fluid">
            <div
              class="
              row
              d-flex
              flex-row
              justify-content-end
              align-items-center
            "
            >
              <div
                class="col-6 mt-lg-5 py-5 px-lg-0 px-4 d-flex align-items-center"
              >
                <div class="content">
                  <p class="font-weight-bold text-white">
                    Create, Collaborate And Distribute Original Content
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position-relative">
          <div class="d-block d-md-none player-mini">
            <PlayerMini />
          </div>
          <div class="items-wrap">
            <div v-for="(song, i) in getTrackDetails.songs" :key="i">
              <div
                class="item-card"
                @click="() => gotoDetail(song)"
                style="cursor: pointer"
              >
                <div class="ul-widget4__body">
                  <img class="card-img-top" :src="song.cover" />
                  <p class="song-title text-16 font-weight-normal p-2 p-lg-3">
                    {{ song.tittle }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import playerSidebar from "@/layouts/common/playerSidebar.vue";
import BannerImg from "@/assets/images/avatar.png";
import PlayerMini from "@/layouts/common/PlayerMini.vue";
export default {
  components: {
    playerSidebar,
    PlayerMini,
  },
  data() {
    return {
      defaultBannerImage: BannerImg,
      progressColor: "#21FB92",
      progressBgColor: "#ffffff",
      hidePlayer: true,
      progressHeightMain: 8,
      volumeHeightMain: 8,
      progressHeightPersistent: 15,
      volumeHeightPersistent: 5,
      circleSize: 300,
      curTime: "00:00",
      curDur: "00:00",
    };
  },
  computed: {
    getTrackDetails() {
      return this.$store.state;
    },
  },
  methods: {
    gotoDetail(song) {
      this.$store.commit("updateSelectedSong", { song });
      const paramId = song.id;
      this.$router.push({ name: "playerDetail", params: { id: paramId } });
    },
  },
};
</script>
<style scoped>
.hero-banner {
  position: relative;
  background: rgb(249 153 124 / 72%);
  height: 300px;
}
.hero-banner::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background: url("../assets/images/player-illustration.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 30%;
  height: 100%;
}
.hero-img img {
  height: 300px;
}
.song-title {
  height: 80px;
}
.player-icon {
  height: fit-content;
}
.player-mini {
  position: sticky;
  top: 0;
  left: 0;
  padding: 1rem 0;
  background: #dcdcdc;
}
.hero-illustration {
  height: 300px;
}
.hero-banner {
  position: relative;
  background: rgb(249 153 124 / 72%);
  height: 300px;
}
.hero-banner::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url("../assets/images/player-illustration.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 30%;
  height: 100%;
}
.hero-banner .content {
  font-size: 36px;
}

.item-card {
  width: 180px;
  margin: 1rem;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.items-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.masthead {
  color: #ffffff;
  width: 100%;
  height: 300px;
  background: rgb(249 153 124 / 72%);
}

.card-img-top {
  width: 100%;
  height: 150px;
}
.main-body {
  position: relative;
  bottom: 2rem;
}
@media (max-width: 767px) {
  .hero-banner::after {
    width: 180px;
    height: 180px;
    margin-top: 1rem;
  }
  .hero-banner {
    height: auto;
  }
  .hero-banner .content {
    font-size: 16px;
  }
  .item-card {
    width: 140px;
  }
  .song-title {
    font-size: 12px;
    height: 55px;
  }
}
</style>
