<template>
  <div class="d-flex flex-column flex-md-row align-items-center" style="padding: 1rem 2rem">
    <div class="col col-lg-3 px-0 song-cover d-flex flex-column flex-md-row">
      <img
        width="70"
        class="d-none d-md-block"
        :src="getTrackDetails.selectedSong.cover"
        alt=""
      />
      <span class="text-white pl-2 font-weight-semibold white-space-wrap"
        >{{ getTrackDetails.selectedSong.tittle }}
        <p class="">{{ getTrackDetails.selectedSong.artist }}</p>
      </span>
    </div>
    <div class="col col-lg-5 d-flex flex-column-reverse flex-lg-column px-0">
      <div class="d-flex flex-row justify-content-between px-0 mb-3">
        <div class="mx-3 d-block player-icon">
          <span @click="playNewTrack(getTrackDetails.selectedSong.prevSong)"
            ><SkipBackwardIcon class="text-white cursor-pointer" w="30" h="30"
          /></span>
        </div>
        <div class="mx-3 d-block player-icon">
          <span
            @click="
              getTrackDetails.isPaused
                ? playPauseTrack()
                : playTrack(getTrackDetails.selectedSong.id)
            "
          >
            <PlayIcon
              class="text-white cursor-pointer"
              v-show="
                !getTrackDetails.isPlaying ||
                  getTrackDetails.isPaused ||
                  getTrackDetails.isStopped
              "
              w="30"
              h="30"
            />
          </span>
          <span @click="pauseTrack()">
            <PauseIcon
              class="text-white cursor-pointer"
              v-show="
                getTrackDetails.isPlaying &&
                  !getTrackDetails.isPaused &&
                  !getTrackDetails.isStopped
              "
              w="30"
              h="30"
            />
          </span>
        </div>
        <div class="mx-3 d-block player-icon">
          <span @click="stopTrack()"
            ><SquareIcon class="text-white cursor-pointer" w="30" h="30"
          /></span>
        </div>
        <div class="mx-3 d-block player-icon">
          <span @click="playNewTrack(getTrackDetails.selectedSong.nextSong)">
            <SkipForwardIcon class="text-white cursor-pointer" w="30" h="30" />
          </span>
        </div>
        <div
          class="mx-3 d-block player-icon"
          :title="
            getTrackDetails.isContinuousPlaying == 2
              ? 'Continuous Playing'
              : getTrackDetails.isContinuousPlaying == 1
              ? 'Current song in loop'
              : ''
          "
        >
          <span
            @click="
              changeContinuousPlay(
                getTrackDetails.isContinuousPlaying === 2
                  ? 0
                  : getTrackDetails.isContinuousPlaying === 1
                  ? 2
                  : 1
              )
            "
            ><RefreshIcon
              :class="
                getTrackDetails.isContinuousPlaying
                  ? 'bg-transparent text-green cursor-pointer'
                  : 'bg-transparent text-white cursor-pointer'
              "
              w="30"
              h="30"
          /></span>
        </div>
      </div>
      <div
        class="row align-items-center justify-content-between progress-plus-time px-4 mb-3 mb-lg-0"
      >
        <div
          class="progress-container col col-12"
          @click="setTrackProgress"
          ref="trackProgressContainer"
        >
          <div class="progress" ref="trackProgress"></div>
        </div>
          <span class="text-sm text-white track-time">
          {{ currentTrackTime }}
        </span>
        <span class="text-sm text-white track-time">
          {{ currentTrackDuration }}
        </span>
      </div>
    </div>
    <div class="col col-lg-3 d-none d-lg-block">
      <div class="d-flex justify-content-end bg-black w-100">
        <div
          class="
            d-flex
            align-items-center
            cursor-pointer
            flex-horizontal-center
          "
        >
          <MdVolumeHighIcon
            v-if="!isMute"
            class="text-white cursor-pointer"
            @click="muteVolume('mute')"
            w="30"
            h="30"
          />
          <MdVolumeOffIcon
            v-else
            class="text-white cursor-pointer"
            @click="muteVolume('unmute')"
            w="30"
            h="30"
          />
          <input
            ref="volumeslider"
            @mousemove="setVolume"
            type="range"
            min="0"
            max="100"
            :value="getTrackDetails.volume * 100"
            step="1"
            class="cursor-pointer volume-slider mt-1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RefreshIcon from "vue-ionicons/dist/md-refresh";
import SkipBackwardIcon from "vue-ionicons/dist/md-skip-backward";
import PlayIcon from "vue-ionicons/dist/md-play";
import PauseIcon from "vue-ionicons/dist/md-pause";
import SquareIcon from "vue-ionicons/dist/md-square";
import SkipForwardIcon from "vue-ionicons/dist/md-skip-forward";
import MdVolumeHighIcon from "vue-ionicons/dist/md-volume-high.vue";
import MdVolumeOffIcon from "vue-ionicons/dist/md-volume-off.vue";
export default {
  data() {
    return {
      currentTrackTime: "00:00",
      currentTrackDuration: "00:00",
      pauseTime: 0,
      currentTrackId: null,
      volume: 1,
      lastVolume: 0,
      isMute: false,
    };
  },
  components: {
    RefreshIcon,
    SkipBackwardIcon,
    PlayIcon,
    PauseIcon,
    SquareIcon,
    SkipForwardIcon,
    MdVolumeHighIcon,
    MdVolumeOffIcon,
  },
  watch: {
    currentTrackTime(val) {
      if (val === this.currentTrackDuration) {
        if (this.getTrackDetails.isContinuousPlaying == 1) {
          this.playTrack(this.getTrackDetails.selectedSong.id);
        } else if (this.getTrackDetails.isContinuousPlaying == 2) {
          this.playTrack(this.getTrackDetails.selectedSong.nextSong);
        } else {
          this.stopTrack();
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getAudio"]),
    getTrackDetails() {
      return this.$store.state;
    },
  },
  methods: {
    ...mapActions([
      "changeCurrentTrack",
      "changeTrack",
      "changeToStopTrack",
      "changeToPauseTrack",
      "changeSeekTrack",
      "changeVolume",
      "changePauseTrack",
      "changeContinuousPlay",
    ]),
    playNewTrack(trackId) {
      if (this.currentTrackId !== trackId) {
        this.currentTrackId = trackId;
        this.playTrack(trackId);
        this.$router.push({ name: "playerDetail", params: { id: trackId } });
      }
    },
    async playTrack(trackId) {
      let song = this.getTrackDetails.songs.find((song) => song.id == trackId);
      await this.changeToStopTrack();
      await this.changeTrack(song);
      this.getTrackDetails.audio.addEventListener(
        "timeupdate",
        this.updateProgress,
        true
      );
    },
    async setTrackProgress(e) {
      const el = this.$refs.trackProgressContainer;
      this.clientWidth = el.clientWidth;
      const clickX = e.offsetX;
      const duration = this.getTrackDetails.audio.duration;
      await this.changeSeekTrack((clickX / this.clientWidth) * duration);
    },
    async updateProgress(e) {
      const progress = this.$refs.trackProgress;
      if (progress && e.srcElement) {
        const { duration, currentTime } = e.srcElement;
        const progressPercent = (currentTime / duration) * 100;
        progress.style.width = `${progressPercent}%`;
        if (
          this.getTrackDetails.audio.duration &&
          this.getTrackDetails.audio.currentTime
        ) {
          this.currentTrackDuration = await this.formatSecondsAsTime(
            this.getTrackDetails.audio.duration
          );
          this.currentTrackTime = await this.formatSecondsAsTime(
            this.getTrackDetails.audio.currentTime
          );
        }
      }
    },
    playPauseTrack() {
      this.changePauseTrack(this.pauseTime);
    },
    pauseTrack() {
      this.pauseTime = this.getTrackDetails.audio.currentTime;
      this.changeToPauseTrack();
    },
    muteVolume(slug) {
      if (slug == "unmute") {
        this.volume = this.lastVolume;
        this.isMute = false;
        this.changeVolume(this.lastVolume);
      } else {
        this.lastVolume = this.volume;
        this.volume = 0;
        this.isMute = true;
        this.changeVolume(0);
      }
    },
    setVolume() {
      let volumeSlider = this.$refs.volumeslider.value;
      this.isMute = false;
      this.volume = volumeSlider / 100;
      this.changeVolume(volumeSlider / 100);
    },
    stopTrack() {
      const progress = this.$refs.trackProgress;
      this.currentTrackTime = "00 : 00";
      progress ? (progress.style.width = `0%`) : null;
      this.changeToStopTrack();
    },
    formatSecondsAsTime(secs) {
      var hr = Math.floor(secs / 3600);
      var min = Math.floor((secs - hr * 3600) / 60);
      var sec = Math.floor(secs - hr * 3600 - min * 60);
      if (min < 10) {
        min = "0" + min;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }
      return min + " : " + sec;
    },
  },
};
</script>
<style scoped>
.player-icon {
  height: fit-content;
}
.right-trackbar {
  position: relative;
  height: calc(100vh - 200px);
  overflow: scroll !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}
.song-wrapper {
  cursor: pointer;
}
.track {
  height: 600px;
}
.lyrics {
  height: 70%;
  white-space: normal;
  overflow-y: scroll;
  line-height: 2.7;
}
.footer {
  height: 15%;
  padding: 2rem;
}
.progress-container,
.volume-slider {
  background: #fff;
  cursor: pointer;
  margin: 0;
  height: 6px;
  border-radius: 100px;
  width: 100%;
  border: 1px solid black;
}
.text-white {
  fill: #ffffff;
}
.progress {
  background-color: rgba(33, 251, 146, 0.6);
  border-radius: 5px;
  height: 100%;
  width: 0%;
  transition: width 0.1s linear;
}
.cursor-pointer {
  cursor: pointer;
}
.flex-horizontal-center {
  align-items: center !important;
  justify-content: center;
}
.song-cover p {
  color: #b3b3b3;
  font-size: 16px;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
@media (max-width: 767px) {
  .song-cover span {
    font-size: 22px;
  }
  .progress-container {
    height: 4px;
  }
  .track-time {
    font-size: 12px;
    color: #b3b3b3 !important;
  }
}
</style>
