
import { createApp } from 'vue'
import App from './App.vue'
// import Vue from 'vue'
import store from './store'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'; 
import XnsSeekBar from "xns-seek-bar";

// Vue.config.productionTip = false

// Vue.filter('doubleDigits', function (val) {
//     if(isNaN(val)){
//       return '00'
//     }else{
//       if(val < 60){
//         return val < 10 ? '0 : 0' + val.toFixed() : '0 : ' + val.toFixed()
//       } else {
//         let seconds = (val % 60).toFixed() == 60 ? '00' : (val % 60).toFixed()
//         let minutes = Math.floor(val/60).toFixed()
//         return minutes + ' : ' + (seconds < 10 ? '0' + seconds : seconds)
//       }
//     }
//   })
  
//   Vue.filter('secsToMinutes', function (val) {
//     if(isNaN(val)){
//       return (val.toFixed() % 60) + ' : '+ Math.floor(val/60)
//     }else{
//       return 0
//     }
//   })
  

const app = createApp(App);

app.use(store);
app.use(router);
app.use(XnsSeekBar);

app.config.globalProperties.$filters = {
  secsToMinutes(val) {
    if(isNaN(val)){
      return (val.toFixed() % 60) + ' : '+ Math.floor(val/60)
    }else{
      return 0
    }
  },
  doubleDigits(val) {
      if(isNaN(val)){
        return '00'
      }else{
        if(val < 60){
          return val < 10 ? '0 : 0' + val.toFixed() : '0 : ' + val.toFixed()
        } else {
          let seconds = (val % 60).toFixed() == 60 ? '00' : (val % 60).toFixed()
          let minutes = Math.floor(val/60).toFixed()
          return minutes + ' : ' + (seconds < 10 ? '0' + seconds : seconds)
        }
      }
    }
};

// app.use(i18n);

app.mount("#app");