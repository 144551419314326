import {
    createRouter,
    createWebHistory
} from 'vue-router';
import Home from "@/views/Home.vue";
import songsListing from "@/views/songsListing.vue";
import playerDetail from "@/views/playerDetail.vue";
import register from "@/views/auth/register.vue";
import signIn from "@/views/auth/signIn.vue";
import forgotpassword from "@/views/auth/forgotpassword.vue";

const routes = [
    {
        path: "/listing",
        name: "songsListing",
        component: songsListing,
    },
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: '/detail/:id',
        name: "playerDetail",
        component: playerDetail,
    },
    {
        path: '/register',
        name: "register",
        component: register,
    },
    {
        path: '/signIn',
        name: "signIn",
        component: signIn,
    },
    {
        path: '/forgotpassword',
        name: "forgotPassword",
        component: forgotpassword,
    },
];

const router = createRouter({
    history: createWebHistory(),
    mode: "history",
    routes,
});


export default router;