<template>
<div class="">
  <div class="wallpaper-login">
    <div class="container">
      <div class="row flex-column-reverse flex-lg-row mx-auto">
        <div class="col-lg-5 px-0">
          <div
            v-if="!registerActive"
            class="card login"
            v-bind:class="{ error: emptyFields }"
          >
            <p class="pb-4 d-none d-lg-block text-blue title mb-0 font-weight-semibold">
              Create Account
            </p>
            <form class="form-group d-flex flex-wrap">
              <div class="col-lg-6">
                <label for="fname" class="mb-0">First Name</label>
                <input
                  v-model="nameReg"
                  type="text"
                  class="form-control"
                  placeholder=""
                  required
                />
              </div>
               <div class="col-lg-6">
                <label for="fname" class="mb-0">Last Name</label>
                <input
                  v-model="nameReg"
                  type="text"
                  class="form-control"
                  placeholder=""
                  required
                />
              </div>
              <div class="col-lg-12">
                <label for="email" class="mb-0">Email</label>
                <input
                  v-model="emailReg"
                  type="email"
                  class="form-control"
                  placeholder=""
                  required
                />
              </div>
              <div class="col-lg-12">
                <label for="username" class="mb-0">Username</label>
                <input
                  v-model="usernameReg"
                  type="text"
                  class="form-control"
                  placeholder=""
                  required
                />
              </div>
              <div class="col-lg-12">
                <label for="password" class="mb-0">Password</label>
                <input
                  v-model="passwordReg"
                  type="password"
                  class="form-control"
                  placeholder=""
                  required
                />
              </div>
              <div class="row px-4 align-items-center">
                <input type="radio" id="terms" name="terms-conditions" value="">
                <label class="pl-2 text-16 text-dark" for="terms">I <span class="text-gray">accept</span> terms and conditons <span class="text-gray">&#38;</span> policy</label>
              </div>
              <input
                type="submit"
                value="Create Account"
                class="col col-lg-5 btn btn-primary w-50"
                @click="doRegister"
              />
            </form>
          </div>
        </div>
        <div class="col-lg-5 px-0 image-container position-relative">
          
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.btn-facebook {
  background: #39528e;
  color: #ffffff;
}
.form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
  transition: none;
}
.form-control:focus {
  border: none !important;
}
label {
  color: #ced4da;
}
.btn-primary {
  background: #574fd8;
}
.title {
  font-size: 30px;
  font-family: sans-serif;
}
.text-gray {
  color: #ced4da;
}
.text-blue {
  color: #574fd8;
}
.image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../assets/images/register-image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.hor-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ced4da;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
.hor-line span {
  background: #fff;
  padding: 0 10px;
}
.text-dark {
  color: #000;
}
.login {
  padding: 2.5rem 2.5rem 0 2.5rem;
  border: none;
}

.form-group input {
  margin-bottom: 20px;
}

.login-page {
  align-items: center;
  display: flex;
  height: 100vh;
}
.wallpaper-login {
  background: #f5f5f5 no-repeat center center;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex; 
  align-items: center; 
}
@media (max-width: 767px) {
  .image-container {
    background-size: cover;
    height: 300px;
    width: 100%;
  }
  .container {
    padding: 0;
  }
}
</style>