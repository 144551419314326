<template>
  <div class="banner-wrapper">
    <section class="container h-50 headline-wrapper text-white py-5">
      <h1 class="title font-weight-bold">Ellorum</h1>
      <p class="subtitle">Welcome to the Music Player!</p>
    </section>
    <section class="container auth-footer">
      <a href="/signin" class="text-decoration-none">
      <input
        type="submit"
        value="Sign In"
        class="btn btn-danger d-block py-2 my-4 col col-lg-4"
      />
      </a>
      <a href="/register" class="text-decoration-none">
      <input
        type="submit"
        value="Sign Up"
        class="btn btn-light d-block py-2 col col-lg-4"
      />
      </a>
    </section>
  </div>
</template>

<style lang="css" scoped>
.banner-wrapper {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url("../assets/images/login-image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.headline-wrapper .title {
  font-size: 52px;
  z-index: 1;
}
.headline-wrapper .subtitle {
  font-size: 20px;
}
.auth-footer .btn{
    font-size: 20px;
    border-radius: 100px;
}
</style>
