<template>
<div class="">
  <div class="wallpaper-login">
    <div class="container">
      <div class="row flex-column-reverse flex-lg-row mx-auto">
        <div class="col-lg-5 px-0">
          <div
            v-if="!registerActive"
            class="card login"
            v-bind:class="{ error: emptyFields }"
          >
            <a href="#" class="google btn d-none d-lg-block text-left">
              <img srcset="https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/2x/external-padlock-cyber-security-inipagistudio-mixed-inipagistudio.png 2x" alt="Padlock icon" loading="lazy">
            </a>
            <p class="text-blue title d-none d-lg-block mb-2 font-weight-semibold">
              Forgot your Password?
            </p>
            <p class="text-left pb-3 text-dark">Enter your registered email address and we will send you a link to reset your password</p>
            <form class="form-group d-flex flex-wrap pb-5">
              <div class="col-lg-12">
                <label for="email" class="mb-0">Email</label>
                <input
                  v-model="emailLogin"
                  type="email"
                  class="form-control"
                  placeholder=""
                  required
                />
              </div>
              <input
                type="submit"
                value="Reset Password"
                class="col col-lg-5 btn btn-primary mb-5"
                @click="resetPassword"
              />
              
            </form>
          </div>
        </div>
         <div class="col-lg-5 px-0 image-container position-relative"></div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.btn-facebook {
  background: #39528e;
  color: #ffffff;
}
.form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
  transition: none;
}
.form-control:focus {
  border: none !important;
}
label {
  color: #ced4da;
}
.btn-primary {
  background: #574fd8;
}
.title {
  font-size: 30px;
  font-family: sans-serif;
}
.text-gray {
  color: #ced4da;
}
.text-blue {
  color: #574fd8;
}
.image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../assets/images/password-page.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.hor-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ced4da;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
.hor-line span {
  background: #fff;
  padding: 0 10px;
}
.text-dark {
  color: #000;
}
.login {
  padding: 2.5rem 2.5rem 0 2.5rem;
  border: none;
}

.form-group input {
  margin-bottom: 20px;
}

.login-page {
  align-items: center;
  display: flex;
  height: 100vh;
}
.wallpaper-login {
  background: #f5f5f5 no-repeat center center;
  background-size: cover;
  height: 100%;
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex; 
  align-items: center; 
}
@media (max-width: 767px) {
  .image-container {
    background-size: cover;
    height: 300px;
    width: 100%;
  }
  .container {
    padding: 0;
  }
}
</style>