<template>
  <div
    class="mx-lg-5 my-lg-5 position-relative"
    v-if="getTrackDetails && getTrackDetails.selectedSong"
  >
    <div class="row flex-column flex-lg-row">
      <div class="col col-12 col-lg-9 track-detail pr-0 mb-5 mb-lg-0">
        <div
          class="w-100 border"
          :class="[collapsed ? 'track-shrink' : 'track']"
          :style="
            'background: url(' +
              getTrackDetails.selectedSong.cover +
              ');background-repeat: no-repeat;background-position:center;background-size: cover'
          "
        >
          <div class="lyrics bg-light m-lg-5 px-lg-5"
          :class="[collapsed ? 'lyrics-shrink' : 'lyrics']"
          >
            <audio ref="currentSong" autoplay />
            <pdf :src="getTrackDetails.selectedSong.lyrcis"></pdf>
          </div>
          <div
            class="
              player-footer
              bg-dark
            "
          >
           <Player/>
            <div class="d-block toggle-list d-lg-none" @click="toggleSongsList()">
              <span>
                <ArrowDropdownIcon
                  class="text-white cursor-pointer"
                  :class="[collapsed ? 'hide' : 'show']"
                  w="30"
                  h="30"
                />
              </span>
              <span>
                <ArrowDropupIcon
                  class="text-white cursor-pointer"
                  :class="[collapsed ? 'show' : 'hide']"
                  w="30"
                  h="30"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col col-12 col-lg-3 right-trackbar px-5 px-lg-0 pt-5 pt-lg-0"
        :class="[collapsed ? 'show' : 'hide']"
      >
        <b-row v-for="song in getTrackDetails.songs" :key="song.id">
          <b-col>
            <div class="row song-wrapper" @click="playNewTrack(song.id)">
              <div class="col col-5 col-lg-7 p-0 cover-wrapper">
                <img class="w-100 h-75" :src="song.cover" />
              </div>
              <div class="col col-7 col-lg-5 song-detail">
                <p class="text-16">
                  <strong class="mr-1">Title:</strong>{{ song.tittle }}
                </p>
                <p class="text-16 d-none d-lg-block">
                  <strong class="mr-1">Artist:</strong>{{ song.artist }}
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import pdf from "vue3-pdf";
import ArrowDropdownIcon from "vue-ionicons/dist/md-arrow-dropdown.vue";
import ArrowDropupIcon from "vue-ionicons/dist/md-arrow-dropup.vue";
import Player from "@/layouts/common/Player.vue";

export default {
  data() {
    return {
      currentTrackTime: "00:00",
      currentTrackDuration: "00:00",
      pauseTime: 0,
      currentTrackId: null,
      volume: 1,
      lastVolume: 0,
      isMute: false,
      collapsed: false,
    };
  },
  components: {
    ArrowDropdownIcon,
    ArrowDropupIcon,
    pdf,
    Player
  },
  watch: {
    currentTrackTime(val) {
      if (val === this.currentTrackDuration) {
        if (this.getTrackDetails.isContinuousPlaying == 1) {
          this.playTrack(this.getTrackDetails.selectedSong.id);
        } else if (this.getTrackDetails.isContinuousPlaying == 2) {
          this.playTrack(this.getTrackDetails.selectedSong.nextSong);
        } else {
          this.stopTrack();
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getAudio"]),
    getTrackDetails() {
      return this.$store.state;
    },
  },
  methods: {
    ...mapActions([
      "changeCurrentTrack",
      "changeTrack",
      "changeToStopTrack",
      "changeToPauseTrack",
      "changeSeekTrack",
      "changeVolume",
      "changePauseTrack",
      "changeContinuousPlay",
    ]),
    toggleSongsList() {
      this.collapsed = !this.collapsed;
    },
    playNewTrack(trackId) {
      if (this.currentTrackId !== trackId) {
        this.currentTrackId = trackId;
        this.playTrack(trackId);
        this.$router.push({ name: "playerDetail", params: { id: trackId } });
      }
    },
    async playTrack(trackId) {
      let song = this.getTrackDetails.songs.find((song) => song.id == trackId);
      await this.changeToStopTrack();
      await this.changeTrack(song);
      this.getTrackDetails.audio.addEventListener(
        "timeupdate",
        this.updateProgress,
        true
      );
    },
    async setTrackProgress(e) {
      const el = this.$refs.trackProgressContainer;
      this.clientWidth = el.clientWidth;
      const clickX = e.offsetX;
      const duration = this.getTrackDetails.audio.duration;
      await this.changeSeekTrack((clickX / this.clientWidth) * duration);
    },
    async updateProgress(e) {
      const progress = this.$refs.trackProgress;
      if (progress && e.srcElement) {
        const { duration, currentTime } = e.srcElement;
        const progressPercent = (currentTime / duration) * 100;
        progress.style.width = `${progressPercent}%`;
        if (
          this.getTrackDetails.audio.duration &&
          this.getTrackDetails.audio.currentTime
        ) {
          this.currentTrackDuration = await this.formatSecondsAsTime(
            this.getTrackDetails.audio.duration
          );
          this.currentTrackTime = await this.formatSecondsAsTime(
            this.getTrackDetails.audio.currentTime
          );
        }
      }
    },
    playPauseTrack() {
      this.changePauseTrack(this.pauseTime);
    },
    pauseTrack() {
      this.pauseTime = this.getTrackDetails.audio.currentTime;
      this.changeToPauseTrack();
    },
    muteVolume(slug) {
      if (slug == "unmute") {
        this.volume = this.lastVolume;
        this.isMute = false;
        this.changeVolume(this.lastVolume);
      } else {
        this.lastVolume = this.volume;
        this.volume = 0;
        this.isMute = true;
        this.changeVolume(0);
      }
    },
    setVolume() {
      let volumeSlider = this.$refs.volumeslider.value;
      this.isMute = false;
      this.volume = volumeSlider / 100;
      this.changeVolume(volumeSlider / 100);
    },
    stopTrack() {
      const progress = this.$refs.trackProgress;
      this.currentTrackTime = "00 : 00";
      progress ? (progress.style.width = `0%`) : null;
      this.changeToStopTrack();
    },
    formatSecondsAsTime(secs) {
      var hr = Math.floor(secs / 3600);
      var min = Math.floor((secs - hr * 3600) / 60);
      var sec = Math.floor(secs - hr * 3600 - min * 60);
      if (min < 10) {
        min = "0" + min;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }
      return min + " : " + sec;
    },
  },
  mounted() {
    if (this?.$route?.params?.id) {
      this.currentTrackId = this.$route.params.id;
      this.changeCurrentTrack(this.$route.params.id);
      this.playTrack(this.$route.params.id);
    }
  },
};
</script>

<style scoped>
.lyric-pdf {
  border: 1px solid red;
}
.player-icon {
  height: fit-content;
}
.right-trackbar {
  position: relative;
  height: calc(100vh - 200px);
  overflow: scroll !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}
.song-wrapper {
  cursor: pointer;
}
.track {
  height: 600px;
}
.lyrics {
  height: 70%;
  white-space: normal;
  overflow-y: scroll;
  line-height: 2.7;
}
.player-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 1rem 0;
  background: #343a40;
}
.progress-container {
  background: #fff;
  cursor: pointer;
  margin: 0;
  height: 12px;
  border-radius: 0;
  width: 100%;
  border: 1px solid black;
}
.text-white {
  fill: #ffffff;
}
.progress {
  background-color: rgba(33, 251, 146, 0.6);
  border-radius: 5px;
  height: 100%;
  width: 0%;
  transition: width 0.1s linear;
}
.cursor-pointer {
  cursor: pointer;
}
.flex-horizontal-center {
  align-items: center !important;
  justify-content: center;
}

.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
@media (max-width: 768px) {
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  .track {
  height: 100vh;
}
  .lyrics {
    height: 100%;
  }
  .lyrics-shrink {
    height: 75%;
  }
  .track-shrink {
    height: 600px;
  }
  .volume-slider {
    width: 100px;
  }
  .player-icon {
    width: 5px;
  }
  .inner-icon svg {
    width: 2em;
    border: 1px solid red;
  }
  /* .player-footer {
    position: fixed;
    right: 0;
    padding: 1rem;
    height: auto
  } */
  .toggle-list {
    position: absolute;
    right: 2rem;
    top: 2.5rem;
  }
  /* .lyrics {
    height: inherit;
  }
  .track {
    height: 90vh;
  } */
  .bg-light {
    background-color: #343a40 !important;
    padding: 2rem;
  }
  .song-wrapper {
    box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06),
      0 1px 4px rgba(0, 0, 0, 0.08);
    margin-bottom: 1.5rem;
    height: 100px;
  }
  .cover-wrapper {
    height: 133px;
  }
  .cover-wrapper img {
    height: 100%;
  }
  .song-detail {
    font-size: 12px;
    padding-top: 1rem;
  }
}
</style>
<style>
.inner-icon svg {
  width: 25px;
  height: 25px;
}
@media (max-width: 767px) {
  .inner-icon svg {
    width: 20px;
    height: 20px;
  }
  /* .lyrics span {
    height: inherit;
  } */
  .lyrics canvas {
    height: 100vh !important;
  }
}
</style>
